<template>
  <div class="vap-page">
    <div class="vap-page-content vasion-flex-vertical-center">
      <div class="home-container">
        <div class="col-12">
          <div class="frame-container">
            <VasionLogoIconOrangeStar class="frame"/>
          </div>
          <p>Check out our new look! All the tools that you love are still here, but we've made it easier for you to manage your business processes in one location.</p>
          <p>The new design offers:</p>
          <ul class="inner-list">
            <li>Access to all of your favorite tools now on the left navigation</li>
            <li>Print + automation in one single location</li>
          </ul>
          <p>Explore all the features below, and more, by clicking the icons on the left:</p>
          <div class="card">
            <div class="icon-container">
              <VasionSideRailCaptureIcon class="icon" />
            </div>
            <div class="text">
              <p>Document Capture: Import or scan documents</p>
            </div>
          </div>
          <div class="card">
            <div class="icon-container">
              <VasionSideRailFormsIcon class="icon" />
            </div>
            <div class="text">
              <p>Forms: Build customizable forms with drag-and-drop fields.</p>
            </div>
          </div>
          <div class="card">
            <div class="icon-container">
              <VasionSideRailSignatureIcon class="icon" />
            </div>
            <div class="text">
              <p>Signature: Collect digital signatures anytime on any smart device.</p>
            </div>
          </div>
          <div class="card">
            <div class="icon-container">
              <VasionSideRailWorkflowIcon class="icon" />
            </div>
            <div class="text">
              <p>Workflow: Optimize and automate the flow of data and documents.</p>
            </div>
          </div>
          <div class="card">
            <div class="icon-container">
              <VasionSideRailStorageIcon class="icon" />
            </div>
            <div class="text">
              <p>Content Management: Manage docs in a centralized cloud repository.</p>
            </div>
          </div>
          <p>New to Automate Pro - <a href="https://printerlogic.com/" target="_blank">Print Automation</a></p>
          <div class="card">
            <div class="icon-container">
              <VasionPrintWhiteIcon class="icon" />
            </div>
            <div class="text">
              <p>Print: Eliminate print servers and automate print output.</p>
            </div>
          </div>
          <p class="fs-12">To start your free trial or to learn more, reach out to your account representative.</p>
          <div class="card learn-card">
            <div class="text learn-text">
              <p class="learn-paragraph">Discover More About Vasion Automate Pro</p>
            </div>
            <div>
              <vasion-button :classProp="'primary'" @vasionButtonClicked="showMore">
                Learn More
              </vasion-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LandingPageInfo',
  methods: {
    showMore() {
      window.open('https://docs.vasion.com/ap/Get_Started/Automate-Pro.htm', '_blank');
    }
  },
}

</script>
<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';

.screen-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-container {
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
}

.main-header {
  font-family: $font-family-accent;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2.4px;
  color: $primary;
  margin: 0 0 0 12px;
}

.logo-container {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 50%;
  flex: 0 0 auto;
  margin: 0 auto;
}

.frame-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.frame {
  max-width: 370px;
  flex: 0 0 auto;
  margin: 0 auto;
}

.card {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.icon-container {
  display: block;
  width: 40px;
  height: 40px;
  background-color: $primary;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.icon {
  flex: 0 0 auto;
  margin: 0 auto;
}

.text {
  flex: 1;
  font-family: Archivo;
  font-size: 14px;

  p {
    margin: 0;
  }
}

.learn-text {
  margin-left: 10px;
}

.learn-card {
  background-color: $primary-container;
  border-radius: 4px;
}

.learn-paragraph {
  color: $primary;
}
@media (max-width: $breakpoint-md) {
  .vasion-flex-vertical-center {
    align-items: start;
    padding-top: 20px;
  }
}
</style>
